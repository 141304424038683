import { createSlice } from '@reduxjs/toolkit';
import { Auth } from 'aws-amplify';

export const initialState = {
    loggedIn: false,
    logInErrors: false,
    showModal: false
};

const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        logInSuccess: state => {
            state.loggedIn = true;
            state.showModal = false
            state.logInErrors = false
        },
        logInFailure: state => {
            state.loggedIn = false
            state.logInErrors = true
        },
        logOutSuccess: state => {
            state.loggedIn = false
        },
        modalHide: state => {
            state.showModal = false
        },
        modalShow: state => {
            state.showModal = true
        }
    }
});

export const {
    logInSuccess,
    logInFailure,
    logOutSuccess,
    modalHide,
    modalShow
} = loginSlice.actions;

export const loginSelector = state => state.login;

export default loginSlice.reducer;

export const attemptLogout = () => dispatch => {
    try {
        Auth.signOut({ global: true });
    } catch (error) {
        console.log('error signing out: ', error);
    }
};

export const checkLogin = () => dispatch => {
    Auth.currentAuthenticatedUser()
        .then(result => {
            dispatch(logInSuccess());
        })
        .catch(e => {
            console.log(e)
            Auth.currentCredentials()
                .then(result => {
                    //console.log(result);
                })
                .catch(e => {
                    console.log(e);
                });
        });
};

export const onAuthEvent = (payload) => dispatch => {
    //console.log('onAuthEvent', payload);
    switch(payload.payload.event){
        case 'signIn':
            console.log('signed in');
            dispatch(logInSuccess());
            break;
        case 'signOut':
            console.log('signed out');
            dispatch(logOutSuccess());
            break;
        case 'signIn_failure':
            console.log('failed to sign in');
            dispatch(logInFailure());
            break;
        default:
            console.log('unused auth event', payload.payload.event);
            break;
    }
};
