import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'react-bootstrap/Button';

import { cancelPostBlurb, aboutSelector, postBlurb, setBlurb, fetchBlurb } from '../slices/about';
import { loginSelector } from '../slices/login';

function AboutPage() {
  const dispatch = useDispatch();
  const { blurb } = useSelector(aboutSelector);
  const { loggedIn } = useSelector(loginSelector);

  useEffect(() => { dispatch(fetchBlurb())}, [dispatch]);

  const renderBlurb = () => {
    if(!loggedIn) return (
      <p className="lead">
        { blurb }
      </p>
    );
    else return (
      <>
        <div className="form-group">
          <textarea
            className="form-control" 
            value={blurb} 
            onChange={e => dispatch(setBlurb(e.target.value))}>
          </textarea>
          <Button variant="primary" onClick={() => dispatch(postBlurb(blurb))}>Update</Button>
          <Button variant="danger" onClick={() => dispatch(cancelPostBlurb())}>Cancel</Button>
        </div>
      </>
    );
  };

  return (
    <div>
        <h3>About us</h3>
        { renderBlurb() }
    </div>
  );
}

export default AboutPage;
