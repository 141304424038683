import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Pencil } from 'react-bootstrap-icons'

import {
  cancelEdit,
  clearImageSrc,
  deleteTheWork,
  editWork, 
  fetchWorks, 
  postWork, 
  setBlurb,
  setLink, 
  setTitle,
  updateTheWork, 
  workSelector 
} from '../slices/work';
import { loginSelector } from '../slices/login';

function WorkPage(){
  const dispatch = useDispatch();
  const { 
    addingWork,
    addingWorkErrors,
    blurb,
    editingWork,
    getWorksErrors,
    id,
    imageSrc,
    link,
    loadingWorks,
    title,
    updatingWorkErrors,
    works
  } = useSelector(workSelector);
  const { loggedIn } = useSelector(loginSelector);

  var imgSrc = [];

  const setImgSrc = (src) => {
    imgSrc = src;
  };
  
  useEffect(() => {dispatch(fetchWorks())}, [dispatch]);

  const renderAddEditCancel = () => {
    if(editingWork) return (
      <div>
        <button 
          type="button" 
          className="btn btn-primary"
          onClick={() => dispatch(updateTheWork(id, blurb, imgSrc, link, title))}>
          Update
        </button>
        <button 
          type="button" 
          className="btn btn-secondary"
          onClick={() => dispatch(cancelEdit())}>
          Cancel
        </button>
        { updatingWorkErrors && <p style={{color: "red"}}>Error Updating Work!</p> }
      </div>
    );
    else return (
    <div>
      <button 
        type="button" 
        className="btn btn-success"
        onClick={() => dispatch(postWork(blurb, imgSrc, link, title))}>
        Add
      </button>
      { addingWorkErrors && <p style={{color: "red"}}>Error Adding Work!</p> }
    </div>
    );
  };

  const renderAddImage = () => {
    if(imageSrc && imageSrc.length > 0) return (
      <div className="form-group">
        <label htmlFor="image">Image</label>
        <span>
          <button className="btn btn-link" onClick={() => dispatch(clearImageSrc())}>
            (remove)
          </button>
        </span>
        <img src={imageSrc} className="form-control" style={{height: "auto"}}></img>
      </div>
    );
    return (
      <div className="form-group">
        <label htmlFor="image">Image</label>
        <input 
          type="file"
          alt="" 
          id="image" 
          onChange={e => {
            setImgSrc(e.target.files[0])
          }}
          className="form-control">
        </input>
      </div>
    );
  };

  const renderAddWorks = () => {
    if(!loggedIn) return '';
    if(addingWork) return <p>Adding Work...</p>
    return(
      <div>
        <p>enter the works</p>
        <form>
          <div className="form-group">
            <label htmlFor="title">Title</label>
            <input 
              type="text" 
              id="title"
              value={title}
              onChange={e => dispatch(setTitle(e.target.value))} 
              className="form-control">
            </input>
          </div>
          <div className="form-group">
            <label htmlFor="blurb">Blurb</label>
            <textarea 
              id="blurb" 
              value={blurb}
              onChange={e => dispatch(setBlurb(e.target.value))} 
              className="form-control">
            </textarea>
          </div>
          <div className="form-group">
            <label htmlFor="link">Link</label>
            <input 
              type="text" 
              id="link" 
              value={link}
              onChange={e => dispatch(setLink(e.target.value))}
              className="form-control">
            </input>
          </div>
          {renderAddImage()}
          {renderAddEditCancel()}
        </form>
      </div>
    );
  };

  const renderShowWorks = () => {
    if(loadingWorks) return (<p>loading works...</p>);
    if(getWorksErrors) return (<p>Unable to find works.</p>);
    if(works.length < 1) return (<p>Coming Soon!</p>);
    return (
      <div className="container">
        <div className="row">{ works.map((work) => (
          <div key={work.id} className="col-6" style={{textAlign: "center"}}>
            <div className="bg-light" style={{margin: "10px", height: "250px"}}>
              {loggedIn && <button 
                type="button" 
                className="close" 
                style={{position: "absolute", top: "10px", left: "30px", color: "blue", zIndex: "100"}}
                onClick={() => dispatch(editWork({ 
                  id: work.id, 
                  blurb: work.blurb, 
                  imgSrc: work.imgSrc, 
                  link: work.link, 
                  title: work.title
                  }))}>
                <span aria-hidden="true"><Pencil></Pencil></span>
              </button>}
              {loggedIn && <button 
                type="button" 
                className="close" 
                aria-label="Close"
                style={{position: "absolute", top: "10px", right: "30px", color: "red", zIndex: "100"}}
                onClick={() => dispatch(deleteTheWork(work.id))}>
                <span aria-hidden="true">&times;</span>
              </button>}
              <div className="row">
                <div className="col">
                  <h4>{work.title}</h4>
                </div>
              </div>
              <a href={work.link} target="_blank" rel="noopener noreferrer">  
                {work.imgSrc && work.imgSrc.length > 0 &&<div className="row">
                  <div className="col">
                    <img src={work.imgSrc} style={{height: "150px"}} alt={work.link}></img>
                  </div>
                </div>}
                <div className="row">
                  <div className="col">
                    <p>{work.blurb}</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        ))}</div>
      </div>
    );
  };

  return (
    <div>
      <h3>Work(s)</h3>
      { renderAddWorks() }
      { renderShowWorks() }
    </div>
  );
}

export default WorkPage;
