import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link} from 'react-router-dom';

import HomePage from './pages/HomePage';
import WorkPage from './pages/WorkPage';
import BlogPage from './pages/BlogPage';
import AboutPage from './pages/AboutPage';

import LoginComponent from './components/LoginComponent';

import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="App">
      <Router>
        <nav className="navbar navbar-dark bg-dark">
          <ul className="nav">
            <li className="nav-item">
              <Link to="/" className="nav-link">Home</Link>
            </li>
            <li className="nav-item">
              <Link to="/work" className="nav-link">Work</Link>
            </li>
            <li className="nav-item">
              <Link to="/blog" className="nav-link">Blog</Link>
            </li>
            <li className="nav-item">
              <Link to="/about" className="nav-link">About</Link>
            </li>
          </ul>
          <LoginComponent></LoginComponent>    
        </nav>
        <header className="jumbotron">
          <h1>Everybody. Gets. On.</h1>
        </header>
        <div className="container">
          <Routes>
            <Route exact path="/" element={<HomePage />} />
            <Route path="/work" element={<WorkPage />} />
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/about" element={<AboutPage />} />
          </Routes>
        </div> 
      </Router>
    </div>
  );
}

export default App;
