/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCopy = /* GraphQL */ `
  query GetCopy($id: ID!) {
    getCopy(id: $id) {
      id
      value
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listCopys = /* GraphQL */ `
  query ListCopys(
    $filter: ModelCopyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCopys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        value
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getWork = /* GraphQL */ `
  query GetWork($id: ID!) {
    getWork(id: $id) {
      id
      title
      blurb
      link
      image
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listWorks = /* GraphQL */ `
  query ListWorks(
    $filter: ModelWorkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        blurb
        link
        image
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      id
      title
      text
      tags
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listPosts = /* GraphQL */ `
  query ListPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        text
        tags
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
