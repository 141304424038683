import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'react-bootstrap/Button';

import { cancelPostBlurb, homeSelector, postBlurb, setBlurb, fetchBlurb } from '../slices/home';
import { loginSelector } from '../slices/login';

function HomePage(){
  const dispatch = useDispatch();
  const { blurb } = useSelector(homeSelector);
  const { loggedIn } = useSelector(loginSelector);

  useEffect(() => {dispatch(fetchBlurb())}, [dispatch]);

  const renderBlurb = () => {
    if(!loggedIn) return (
      <p className="lead">
        { blurb }
      </p>
    );
    else return (
      <>
        <div className="form-group">
          <textarea
            className="form-control" 
            value={blurb} 
            onChange={e => dispatch(setBlurb(e.target.value))}>
          </textarea>
          <Button variant="primary" onClick={() => dispatch(postBlurb(blurb))}>Update</Button>
          <Button variant="danger" onClick={() => dispatch(cancelPostBlurb())}>Cancel</Button>
        </div>
      </>
    );
  };

  return (
    <div>{ renderBlurb() }</div>
  );
}

export default HomePage;
