import { createSlice } from '@reduxjs/toolkit';
import { API, graphqlOperation } from 'aws-amplify';
import { listPosts } from '../graphql/queries';
import { createPost, deletePost, updatePost } from '../graphql/mutations';

export const initialState = {
    addingPost: false,
    addingPostErrors: false,
    getPostsErrors: false,
    loadingPosts: false,
    posts: [],
    removingPost: false,
    removingPostErrors: false,
    search: "",
    tag: "",
    tags: [],
    text: "",
    title: "",
    updatingPost: false,
    updatingPostErrors: false
};

const blogSlice = createSlice({
    name: 'blog',
    initialState,
    reducers: {
        addPost: state => {
            state.addingPost = true
            state.addingPostErrors = false
        },
        addPostSuccess: (state, payload) => {
            state.posts = (state.posts || []).concat(payload.payload)
            state.tag = ""
            state.tags = []
            state.text = ""
            state.title = ""
            state.addingPost = false
            state.addingPostErrors = false
        },
        addPostFailure: (state, payload) => {
            state.addingPost = false
            state.addingPostErrors = true
        },
        addTag: (state, payload) => {
            state.tags = (state.tags || []).concat(
                payload.payload
            )
            state.tag = ""
        },
        addTagToPost: (state, payload) => {
            state.posts = state.posts.map(post => {
                if(post.id !== payload.payload.id)
                    return post;
                post.tags = (post.tags || []).concat(
                    payload.payload.tag
                )
                return post;
            })
            state.tag = ""
        },
        getPosts: state => {
            state.loadingPosts = true
            state.getPostsErrors = false
        },
        getPostsSuccess: (state, {payload}) => {
            state.posts = payload
            state.loadingPosts = false
            state.getPostsErrors = false
        },
        getPostsFailure: state => {
            state.loadingPosts = false
            state.getPostsErrors = true
        },
        removePost: state => {
            state.removingPost = true
            state.removingPostErrors = false
        },
        removePostSuccess: (state, payload) => {
            state.posts = state.posts.filter(post => post.id !== payload.payload.id)
            state.removingPost = false
            state.removingPostErrors = false
        },
        removePostFailure: (state, payload) => {
            state.removingPost = false
            state.removingPostErrors = true
        },
        removeTag: (state, payload) => {
            state.tags = state.tags.splice(payload.payload, 1)
        },
        removeTagFromPost: (state, payload) => {
            state.posts = state.posts.map(post => {
                if(post.id !== payload.payload.id)
                    return post;
                post.tags = (post.tags).filter(tag => tag !== payload.payload.tag);
                return post;
            })
        },
        setPostNewTag: (state, payload) => {
            state.posts = state.posts.map(post => {
                if(post.id !== payload.payload.id)
                    return post;
                post.newTag = payload.payload.tag;
                return post;
            })
        },
        setSearch: (state, payload) => {
            state.search = payload.payload
        },
        setTag: (state, payload) => {
            state.tag = payload.payload
        },
        setText: (state, payload) => {
            state.text = payload.payload
        },
        setTextOnPost: (state, payload) => {
            state.posts = state.posts.map(post => {
                if(post.id !== payload.payload.id)
                    return post;
                post.text = payload.payload.text;
                return post;
            })
        },
        setTitle: (state, payload) => {
            state.title = payload.payload
        },
        setTitleOnPost: (state, payload) => {
            state.posts = state.posts.map(post => {
                if(post.id !== payload.payload.id)
                    return post;
                post.title = payload.payload.text;
                return post;
            })
        },
        toggleEditPost: (state, payload) => {
            state.posts = state.posts.map(post => {
                if(post.id === payload.payload){ 
                    post.editing = !!!post.editing
                    post.newTag = ""
                }
                return post
            })
        },
        updatingPost: (state, payload) => {
            state.updatingPost = true;
            state.updatingPostErrors = false;
        },
        updatingPostSuccess: (state, payload) => {
            state.updatingPost = false;
            state.updatingPostErrors = false;
        },
        updatingPostFailure: (state, payload) => {
            state.updatingPost = false;
            state.updatingPostErrors = true;
        }
    }
});

export const {
    addPost,
    addPostSuccess,
    addPostFailure,
    addTag, 
    addTagToPost,
    getPosts, 
    getPostsSuccess, 
    getPostsFailure,
    removePost,
    removePostSuccess,
    removePostFailure,
    removeTag,
    removeTagFromPost,
    setPostNewTag,
    setSearch,
    setTag, 
    setText,
    setTextOnPost,
    setTitle,
    setTitleOnPost,
    toggleEditPost,
    updatingPost,
    updatingPostSuccess,
    updatingPostFailure 
} = blogSlice.actions;

export const blogSelector = state => state.blog;

export default blogSlice.reducer;

export function deleteThePost(id) {
    return dispatch => {
        dispatch(removePost);

        API.graphql(graphqlOperation(
            deletePost,
            {
                input: {
                    id: id
                }
            }
        ))
        .then((response) => {
            dispatch(
                removePostSuccess({id: id})
            );
        })
        .catch((err) => {
            removePostFailure(err)
        });
    }
};

export function fetchPosts(filter){
    return dispatch => {
        dispatch(getPosts());

        var variables = {
            limit: 10
        };

        if(filter && filter.length > 0)
            variables.filter = {
                tags: {
                    contains: filter
                }
            };

        API.graphql({
            query: listPosts,
            variables: variables,
            authMode: "AWS_IAM"
        })
        .then((result) => {
            dispatch(result.data.listPosts ? 
                getPostsSuccess(result.data.listPosts.items) : 
                getPostsFailure());
        },
        (error) => {
            dispatch(getPostsFailure());
            console.log(error);
        });
    };
};

export function postPost(title, text, tags){
    return dispatch => {
        dispatch(addPost);

        API.graphql(graphqlOperation(
            createPost,
            {
                input: {
                    title: title,
                    text: text,
                    tags: tags
                }
            }
        ))
        .then((response) => {
            dispatch(addPostSuccess({
                id: Math.floor(Math.random() * Math.floor(100)),
                title: title,
                text: text,
                datePosted: Date.now().toLocaleString("en-US"),
                dateEdited: "",
                tags: tags,
            }));
        })
        .catch((err) => {
            dispatch(addPostFailure(err));
        });
    };
};

export function updateThePost(id, title, text, tags){
    return dispatch => {
        dispatch(updatingPost);

        API.graphql(graphqlOperation(
            updatePost,
            {
                input: {
                    id: id,
                    title: title,
                    text: text,
                    tags: tags
                }
            }
        ))
        .then((response) => {
            dispatch(updatingPostSuccess);
            dispatch(toggleEditPost(id));
        })
        .catch((err) => {
            dispatch(updatingPostFailure(err));
        });
    }
};
