import React from 'react';
import { createRoot } from 'react-dom/client';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import Amplify from "aws-amplify";
import awsExports from "./aws-exports";

import App from './App';
import rootReducer from './slices';

import * as serviceWorker from './serviceWorker';

const store = configureStore({ reducer: rootReducer });
const container = document.getElementById('root');
const root = createRoot(container);

Amplify.configure(awsExports);
root.render(
    // Doesn't work with react-bootstrap
    <React.StrictMode>
        <Provider store={store}>    
            <App />
        </Provider>
    </React.StrictMode>
);

serviceWorker.unregister();
