import { combineReducers } from 'redux';

import aboutReducer from './about';
import blogReducer from './blog';
import homeReducer from './home';
import loginReducer from './login';
import workReducer from './work';

const rootReducer = combineReducers({
    about: aboutReducer,
    blog: blogReducer,
    home: homeReducer,
    login: loginReducer,
    work: workReducer
});

export default rootReducer;
