/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:e186b596-2d64-4380-8b99-6e10a4b796b0",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_1Nj51QtG3",
    "aws_user_pools_web_client_id": "1tl5tgsc7h5oqiuf82skrfpo8k",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://z7rrttad4fgq7lelfrlwdk4vhy.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "everybody-gets-images93438-dev",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_content_delivery_bucket": "everybodygtson.com-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "http://everybodygtson.com-dev.s3-website-us-east-1.amazonaws.com"
};


export default awsmobile;
