import { createSlice } from '@reduxjs/toolkit';
import { API, graphqlOperation } from 'aws-amplify';
import { getCopy } from '../graphql/queries';
import { createCopy, updateCopy } from '../graphql/mutations';
import { isLocalhost as debug } from '../serviceWorker';

export const initialState = {
    blurb: "Finally, a website you can look at.",
    blurbDefault: "Because... why not?",
    debug: debug,
    getBlurbErrors: false,
    loadingBlurb: false,
    updatingBlurb: false,
    setBlurbErrors: false
};

const homeSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {
        cancelPostBlurb: state => {
            state.blurb = state.blurbDefault
        },
        gettingBlurb: state => {
            state.loadingBlurb = true
        },
        getBlurbSuccess: (state, payload) => {
            state.blurbDefault = payload.payload
            state.blurb = state.blurbDefault
            state.loadingBlurb = false
            state.getBlurbErrors = false
        },
        getBlurbFailure: (state, payload) => {
            state.blurb = state.blurbDefault
            state.loadingBlurb = false
            state.getBlurbErrors = true
        },
        setBlurb: (state, payload) => {
            state.blurb = payload.payload
        },
        postingBlurb: state => {
            state.postingBlurb = true
            state.setBlurbErrors = false
        },
        postBlurbSuccess: (state, payload) => {
            state.blurbDefault = payload.payload
            state.postingBlurb = false
            state.setBlurbErrors = false
        },
        postBlurbFailure: (state, payload) => {
            state.postingBlurb = false
            state.setBlurbErrors = true
        }
    }
});

export const {
    cancelPostBlurb,
    gettingBlurb,
    getBlurbSuccess,
    getBlurbFailure,
    postingBlurb,
    postBlurbSuccess,
    postBlurbFailure,
    setBlurb
} = homeSlice.actions;

export const homeSelector = state => state.home;

export default homeSlice.reducer;

export function fetchBlurb(){
    return dispatch => {
        dispatch(gettingBlurb());

        API.graphql({
            query: getCopy, 
            variables: { id: "home_blurb" },
            authMode: "AWS_IAM"
        })
        .then((result) => {
            dispatch(
                result.data.getCopy ? 
                    getBlurbSuccess(result.data.getCopy.value) : 
                    getBlurbFailure());
        },
        (error) => {
            dispatch(getBlurbFailure());
        });
    };
}; 

export function postBlurb(blurb){
    return (dispatch, getState) => {
        dispatch(postingBlurb);

        API.graphql(graphqlOperation(
            getState().home.getBlurbErrors ? 
                createCopy : 
                updateCopy, 
            {
                input: {
                    id: 'home_blurb',
                    value: blurb
                }
            }
        ))
        .then((response) => {
            dispatch(postBlurbSuccess(response));
        })
        .catch((err) => {
            dispatch(postBlurbFailure(err));
        });
    };
};
