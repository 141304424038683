import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Hub } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';

import { 
  loginSelector, 
  attemptLogout,
  checkLogin, 
  modalShow, 
  modalHide, 
  onAuthEvent
} from '../slices/login';

function LoginComponent() {
  const dispatch = useDispatch();
  const {
    loggedIn,
    showModal
  } = useSelector(loginSelector);
  const handleClose = () => dispatch(modalHide());
  const handleShow = () => dispatch(modalShow());
  const handleLogout = () => dispatch(attemptLogout());

  useEffect(() => {
    Hub.listen('auth', (data) => {
      dispatch(onAuthEvent(data))
    }) 
  }, [dispatch]);

  useEffect(() => dispatch(checkLogin()), [dispatch]);

  return (
    <div>
      <Button 
        variant="primary" 
        size="sm"
        onClick={loggedIn ? handleLogout : handleShow}>
        { loggedIn ? "Logout" : "Login"}
      </Button>
      <Modal 
        show={showModal} 
        onHide={handleClose}
        style={{ textAlign: "center"}}>
        <Authenticator 
          hideSignUp={true}
          className='p-2'>
          {({ signOut, user }) => (
            <main>
              <h1>Hello {user.username}</h1>
              <button onClick={signOut}>Sign out</button>
            </main>
          )}
        </Authenticator>
      </Modal>
    </div>
  );
}

export default LoginComponent;
