/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCopy = /* GraphQL */ `
  mutation CreateCopy(
    $input: CreateCopyInput!
    $condition: ModelCopyConditionInput
  ) {
    createCopy(input: $input, condition: $condition) {
      id
      value
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateCopy = /* GraphQL */ `
  mutation UpdateCopy(
    $input: UpdateCopyInput!
    $condition: ModelCopyConditionInput
  ) {
    updateCopy(input: $input, condition: $condition) {
      id
      value
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteCopy = /* GraphQL */ `
  mutation DeleteCopy(
    $input: DeleteCopyInput!
    $condition: ModelCopyConditionInput
  ) {
    deleteCopy(input: $input, condition: $condition) {
      id
      value
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createWork = /* GraphQL */ `
  mutation CreateWork(
    $input: CreateWorkInput!
    $condition: ModelWorkConditionInput
  ) {
    createWork(input: $input, condition: $condition) {
      id
      title
      blurb
      link
      image
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateWork = /* GraphQL */ `
  mutation UpdateWork(
    $input: UpdateWorkInput!
    $condition: ModelWorkConditionInput
  ) {
    updateWork(input: $input, condition: $condition) {
      id
      title
      blurb
      link
      image
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteWork = /* GraphQL */ `
  mutation DeleteWork(
    $input: DeleteWorkInput!
    $condition: ModelWorkConditionInput
  ) {
    deleteWork(input: $input, condition: $condition) {
      id
      title
      blurb
      link
      image
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createPost = /* GraphQL */ `
  mutation CreatePost(
    $input: CreatePostInput!
    $condition: ModelPostConditionInput
  ) {
    createPost(input: $input, condition: $condition) {
      id
      title
      text
      tags
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updatePost = /* GraphQL */ `
  mutation UpdatePost(
    $input: UpdatePostInput!
    $condition: ModelPostConditionInput
  ) {
    updatePost(input: $input, condition: $condition) {
      id
      title
      text
      tags
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deletePost = /* GraphQL */ `
  mutation DeletePost(
    $input: DeletePostInput!
    $condition: ModelPostConditionInput
  ) {
    deletePost(input: $input, condition: $condition) {
      id
      title
      text
      tags
      createdAt
      updatedAt
      owner
    }
  }
`;
