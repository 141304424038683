import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Check2, Pencil, Search, XCircle } from 'react-bootstrap-icons'

import { 
  blogSelector, 
  addTag,
  addTagToPost,
  deleteThePost, 
  fetchPosts,
  postPost, 
  removeTag,
  removeTagFromPost,
  setPostNewTag,
  setSearch,
  setTag,
  setText,
  setTextOnPost, 
  setTitle,
  setTitleOnPost,
  toggleEditPost,
  updateThePost } from '../slices/blog';
import { loginSelector } from '../slices/login';

function BlogPage() {
  const dispatch = useDispatch();
  const { 
    addingPost,                 
    addingPostErrors,
    getPostsErrors,
    loadingPosts,
    posts,
    search,
    tag,
    tags,
    text, 
    title } = useSelector(blogSelector);
  const { loggedIn } = useSelector(loginSelector);

  useEffect(() => { dispatch(fetchPosts())}, [dispatch]);

  const renderAddPosts = () => {
    if(!loggedIn) return '';
    if(addingPost) return <p>Adding Post...</p>
    return(
      <div>
        <p>enter the posts</p>
        <form>
          <div className="row form-group">
            <div className="col">
              <label htmlFor="title">Title</label>
              <input 
                type="text" 
                id="title"
                value={title}
                onChange={e => dispatch(setTitle(e.target.value))} 
                className="form-control">
              </input>
            </div>
          </div>
          <div className="row form-group">
            <div className="col">
              <label htmlFor="text">Text</label>
              <textarea 
                id="text" 
                value={text}
                onChange={e => dispatch(setText(e.target.value))} 
                className="form-control">
              </textarea>
            </div>
          </div>
          <div className="row form-group">
            <div className="col">
              <label htmlFor="tag">Tags</label>
              <div className="input-group">
                <input 
                  type="text" 
                  id="tag" 
                  value={tag}
                  onChange={e => dispatch(setTag(e.target.value))}
                  className="form-control"
                  aria-describedby="tag-adder">
                </input>
                <div className="input-group-append">
                  <button 
                    id="tag-adder"
                    className="btn btn-outline-secondary" 
                    type="button" 
                    onClick={() => dispatch(addTag(tag))}>Add Tag
                  </button>
                </div>
              </div>
              <div>
                {tags.map((tag, index) => (
                  <div 
                    key={index} 
                    className="input-group" 
                    style={{
                      width: "auto", 
                      float: "left",
                      padding: "0px 2px 2px 0px"
                    }}>
                    <div className="input-group-prepend">
                      <span 
                        className="input-group-text" 
                        id={"tag_"+index}>
                        {tag}
                      </span>
                    </div>
                    <button 
                      type="button" 
                      className="btn btn-danger" 
                      aria-describedby={"tag_"+index} 
                      onClick={() => dispatch(removeTag(index))}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="row form-group">
            <div className="col">
              <button 
                type="button" 
                className="btn btn-primary"
                onClick={() => dispatch(postPost(title, text, tags))}>Add
              </button>
            </div>
          </div>
          { addingPostErrors && <p style={{color: "red"}}>Error Adding Post!</p> }
        </form>
        <hr></hr>
      </div>
    );
  };

  const renderEdit = (post) => {
    if(post.editing) return (
      <div style={{display: "inline-block"}}>
        <button 
          className="btn btn-sm btn-secondary"
          onClick={() => dispatch(toggleEditPost(post.id))}>
          <XCircle></XCircle>
        </button>
        <button 
          className="btn btn-sm btn-success"
          onClick={() => dispatch(updateThePost(post.id, post.title, post.text, post.tags))}>
          <Check2></Check2>
        </button>
      </div>
    );
    return (
      <button 
        className="btn btn-sm btn-primary"
        onClick={() => dispatch(toggleEditPost(post.id))}>
        <Pencil></Pencil>
      </button>
    );
  };

  const renderPostText = (post) => {
    if(post.editing) return (
      <textarea 
        className="form-control"
        value={post.text}
        onChange={e => dispatch(setTextOnPost({id: post.id, text: e.target.value}))}>
      </textarea>
    );
    return <p>{post.text}</p>
  };

  const sortedPosts = [].concat(posts).sort((a, b) => 
    (Date.parse(a.dateEdited) - Date.parse(b.dateEdited)) * -1
  );

  const renderShowPosts = () => {
    if(loadingPosts) return (<p>loading posts...</p>);
    if(getPostsErrors) return (<p>Unable to find posts.</p>);
    return (
      <div className="container">
        <div className="row">{ sortedPosts.map((post) => (
          <div key={post.id} className="col-12" style={{textAlign: "center"}}>
            <div className="bg-light container d-flex flex-column" style={{margin: "10px", minHeight: "250px"}}>
              <div className="row form-group">
                <div className="col">
                  <label style={{position: "absolute", left: "0px", marginLeft: ".5rem"}}>{new Date(post.updatedAt ? post.updatedAt : post.createdAt).toDateString()}</label>
                  
                  {!post.editing && <h4 style={{display: "inline-block", padding: "0px 128px"}}>{post.title}</h4>}
                  {loggedIn && 
                    renderEdit(post)
                  }
                  {loggedIn &&
                    <button 
                      type="button" 
                      className="close" 
                      aria-label="Close"
                      style={{
                        float: "right",
                        color: "red"
                      }}
                      onClick={() => dispatch(deleteThePost(post.id))}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  }
                </div>
              </div>
              {loggedIn && post.editing && 
                <div className="row form-group">
                  <div className="col">
                    <input 
                      type="text" 
                      value={post.title}
                      onChange={e => dispatch(setTitleOnPost({id: post.id, text: e.target.value}))}
                      className="form-control">
                    </input>
                  </div>
                </div>
              }
              <div className="row form-group">
                <div className="col">
                  {renderPostText(post)}
                </div>
              </div>
              {loggedIn && post.editing && <div className="row form-group">
                <div className="col">
                  <div className="input-group">
                    <input 
                      type="text" 
                      id={"tag_" + post.id} 
                      value={post.newTag}
                      onChange={e => dispatch(setPostNewTag({id: post.id, tag: e.target.value}))}
                      className="form-control"
                      aria-describedby={"tag-adder_"+post.id}>
                    </input>
                    <div className="input-group-append">
                      <button 
                        id={"tag-adder_"+post.id}
                        className="btn btn-outline-secondary" 
                        type="button" 
                        onClick={() => dispatch(addTagToPost({id: post.id, tag: post.newTag}))}>Add Tag
                      </button>
                    </div>
                  </div>
                </div>
              </div>}
              <div 
                className="row form-group flex-grow-1"
                style={{ marginTop: "50px" }}>
                <div className="col">
                  <div style={{
                    position: "absolute",
                    bottom: "0px",
                    paddingBottom: "10px"
                  }}>
                    { post.tags.map((tag, index) => (
                      <div 
                        key={index} 
                        className="input-group" 
                        style={{
                          width: "auto", 
                          float: "left",
                          padding: "0px 2px 2px 0px"
                        }}>
                        <div className="input-group-prepend">
                          <span 
                            className="input-group-text" 
                            id={post.id+"_"+index}>
                            {tag}
                          </span>
                        </div>
                        { loggedIn && post.editing &&
                          <button 
                            type="button" 
                            className="btn btn-danger" 
                            aria-describedby={post.id+"_"+index} 
                            onClick={() => dispatch(removeTagFromPost({id: post.id, tag: tag}))}>
                              <span aria-hidden="true">&times;</span>
                          </button>
                        }
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          ))
        }</div>
      </div>
    );
  };

  const renderSearch = () => {
    return (
      <div 
        className="input-group" 
        style={{
          float: "right",
          width: "unset"
        }}>
        <input 
          type="text"
          value={search} 
          onChange={e => {
            dispatch(setSearch(e.target.value));
            dispatch(fetchPosts(e.target.value))
          }}
          placeholder="Search by tag" 
          aria-label="Search by tag" 
          aria-describedby="searchTag">
        </input>
        <div className="input-group-append">
          <span className="input-group-text" id="searchTag">
            <Search></Search>
          </span>
        </div>
      </div>
    );
  };

  return (
    <div>
      <h3 style={{display: "inline-block"}}>Blog</h3>
      { renderSearch() }
      { renderAddPosts() }
      { renderShowPosts() }
    </div>
  );
}

export default BlogPage;
