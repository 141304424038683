import { createSlice } from '@reduxjs/toolkit';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import { listWorks } from '../graphql/queries';
import { createWork, deleteWork, updateWork } from '../graphql/mutations';

export const initialState = {
    addingWork: false,
    addingWorkErrors: false,
    blurb: "",
    editingWork: false,
    getWorksErrors: false,
    id: "",
    imageSrc: "",
    link: "",
    loadingWorks: false,
    removingWork: false,
    removingWorkErrors: false,
    title: "",
    pdatingWorkErrors: false,
    uploadingImage: false,
    uploadingImageErrors: false,
    works: []
};

const workSlice = createSlice({
    name: 'work',
    initialState,
    reducers: {
        addWork: state => {
            state.addingWork = true
            state.addingWorkErrors = false
        },
        addWorkSuccess: (state, payload) => {
            state.works = (state.works || []).concat(payload.payload)
            state.title = ""
            state.blurb = ""
            state.link = ""
            state.imageSrc = ""
            state.addingWork = false
            state.addingWorkErrors = false
        },
        addWorkFailure: (state, payload) => {
            state.addingWork = false
            state.addingWorkErrors = true
        },
        cancelEdit: (state, payload) => {
            state.editingWork = false;
            state.id = ""
            state.title = ""
            state.blurb = ""
            state.link = ""
            state.imageSrc = ""
        },
        clearImageSrc: state => {
            state.imageSrc = ""
        },
        editWork: (state, payload) => {
            state.editingWork = true;
            state.id = payload.payload.id
            state.title = payload.payload.title
            state.blurb = payload.payload.blurb
            state.link = payload.payload.link
            state.imageSrc = payload.payload.imgSrc
        },
        fetchImageFailure: state => {

        },
        fetchImageSuccess: (state, payload) => {
            state.works = state.works.map(work => {
                if(work.id === payload.payload.item.id)
                    work.imgSrc = payload.payload.image;
                return work;
            })
        },
        getWorks: state => {
            state.loadingWorks = true
            state.getWorksErrors = false
        },
        getWorksSuccess: (state, {payload}) => {
            if(payload && payload.length > 0){
                state.worksDefault = payload
                state.works = state.worksDefault
            }
            state.loadingWorks = false
            state.getWorksErrors = false
        },
        getWorksFailure: state => {
            state.works = state.worksDefault
            state.loadingWorks = false
            state.getWorksErrors = true
        },
        removeImageFailure: state => {

        },
        removeImageSuccess: state => {

        },
        removeWork: state => {
            state.removingWork = true
            state.removingWorkErrors = false
        },
        removeWorkSuccess: (state, payload) => {
            state.works = state.works.filter(work => work.id !== payload.payload.id)
            state.removingWork = false
            state.removingWorkErrors = false
        },
        removeWorkFailure: (state, payload) => {
            state.removingWork = false
            state.removingWorkErrors = true
        },
        setBlurb: (state, payload) => {
            state.blurb = payload.payload
        },
        setLink: (state, payload) => {
            state.link = payload.payload
        },
        setTitle: (state, payload) => {
            state.title = payload.payload
        },
        updatingWork: state => {
            state.editingWork = true
            state.updatingWorkErrors = false
        },
        updateWorkSuccess: (state, payload) => {
            state.editingWork = false
            state.updatingWorkErrors = false
            state.works = state.works.map(work => {
                if(work.id === payload.payload.id)
                    work = payload.payload;
                return work;
            })
            state.id = ""
            state.title = ""
            state.blurb = ""
            state.link = ""
            state.imageSrc = ""
        },
        updateWorkFailure: (state, payload) => {
            state.editingWork = false
            state.updatingWorkErrors = true
        },
        uploadingImage: state => {
            state.uploadingImage = true
            state.uploadingImageErrors = false
        },
        uploadImageSuccess: (state, payload) => {
            state.uploadingImage = false
            state.uploadingImageErrors = false
        },
        uploadImageFailure: (state, payload) => {
            state.uploadingImage = false
            state.uploadingImageErrors = true
        }
    }
});

export const {
    addWork,
    addWorkSuccess,
    addWorkFailure,
    cancelEdit,
    clearImageSrc,
    editWork,
    fetchImageFailure,
    fetchImageSuccess, 
    getWorks, 
    getWorksSuccess, 
    getWorksFailure,
    removeImageFailure,
    removeImageSuccess,
    removeWork,
    removeWorkSuccess,
    removeWorkFailure, 
    setBlurb,
    setLink,
    setTitle, 
    updatingWork,
    updateWorkSuccess,
    updateWorkFailure,
    uploadingImage,
    uploadImageSuccess,
    uploadImageFailure
} = workSlice.actions;

export const workSelector = state => state.work;

export default workSlice.reducer;

export function deleteTheWork(id) {
    return (dispatch, getState) => {
        dispatch(removeWork);

        API.graphql(graphqlOperation(
            deleteWork,
            {
                input: {
                    id: id
                }
            }
        ))
        .then((response) => {
            //remove image
            dispatch(removeImage(getWorkImageByID(getState(), id)));
            
            dispatch(
                removeWorkSuccess({
                    id: id
                })
            );
        })
        .catch((err) => {
            removeWorkFailure(err);
        });
    }
};

function fetchImages(items){
    return dispatch => {
        for(let i =0; i < items.length; i++){
            if(items[i].image)
                Storage.get(items[i].image)
                    .then(result => {
                        console.log(result);
                        dispatch(fetchImageSuccess({ 
                            item: items[i], 
                            image: result
                        }));
                    })
                    .catch(err => {
                        console.log(err);
                        dispatch(fetchImageFailure(err));
                    });
        }
    }
};

export function fetchWorks(){
    return dispatch => {
        dispatch(getWorks());

        API.graphql({
            query: listWorks,
            variables: {
                limit: 10
            },
            authMode: "AWS_IAM"
        })
        .then((result) => {
            if(result.data.listWorks){
                dispatch(getWorksSuccess(result.data.listWorks.items));
                if(result.data.listWorks.items.length > 0)
                    dispatch(fetchImages(result.data.listWorks.items));
            }
            else {
                dispatch(getWorksFailure());
            }
        },
        (error) => {
            dispatch(getWorksFailure());
            //console.log(error);
        });
    };
};

function getWorkImageByID(state, id){
    var result = state.work.works.map(work => {
        if(work.id === id)
            return work.image;
        }
    );
    
    return result ? result[0] : null;
};

export function postWork(blurb, img, link, title){
    return dispatch => {
        dispatch(addWork);

        API.graphql(graphqlOperation(
            createWork,
            {
                input: {
                    blurb: blurb, 
                    image: img.name,
                    link: link,
                    title: title
                }
            }
        ))
        .then((response) => {
            dispatch(addWorkSuccess({
                blurb: blurb, 
                link: link,
                title: title
            }));

            if(img)
                dispatch(uploadImage(img));
            else
                dispatch(fetchWorks());
        })
        .catch((err) => {
            dispatch(addWorkFailure(err));
        });
    };
};

function removeImage(imgName){
    return dispatch => {
        console.log(imgName);
        Storage.remove(imgName)
            .then(result => {
                console.log(result);
                dispatch(removeImageSuccess);
            })
            .catch(err => {
                console.log(err);
                dispatch(removeImageFailure(err));
            });
    }
};

export function updateTheWork(id, blurb, img, link, title) {
    return (dispatch, getState) => {
        dispatch(updatingWork);

        var imgName = img && img.name && typeof img.name == "string" ? 
            img.name : 
            "";
        var oldImg = getWorkImageByID(getState(), id);

        API.graphql(graphqlOperation(
            updateWork,
            {
                input: {
                    id: id,
                    blurb: blurb, 
                    image: imgName,
                    link: link,
                    title: title
                }
            }
        ))
        .then((response) => {
            dispatch(
                updateWorkSuccess({
                    id: id,
                    blurb: blurb, 
                    image: imgName,
                    link: link,
                    title: title
                })
            );

            if(img && oldImg !== imgName)
                dispatch(uploadImage(img));
            else
                dispatch(fetchWorks());
            
            if(oldImg !== imgName)
                dispatch(removeImage(oldImg));
        })
        .catch((err) => {
            dispatch(updateWorkFailure(err));
        });
    }
};

function uploadImage(img){
    return dispatch => {
        console.log(img);
        if(img && img.name && typeof img.name == "string")
            Storage.put(
                img.name,
                img)
                .then(result => {
                    console.log(result);
                    dispatch(uploadImageSuccess);
                    dispatch(fetchWorks());
                })
                .catch(err => {
                    console.log(err);
                    dispatch(uploadImageFailure(err));
                });
    }
};
